import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { WebAuth } from "auth0-js";
import SecureLS from "secure-ls";

interface User {
  email: string;
  name: string;
  nickname: string;
  picture: string;
}

const ls = new SecureLS({
  encodingType: "rc4",
  isCompression: false,
  encryptionSecret: import.meta.env.RAM_STORAGE_SECRET,
});

export const useAuthStore = defineStore("authStore", () => {
  const auth0 = new WebAuth({
    domain: import.meta.env.AUTH0_DOMAIN,
    clientID: import.meta.env.AUTH0_CLIENT_ID,
    redirectUri: `${window.location.origin}/auth0callback`,
    responseType: "token id_token",
    scope: "openid profile email",
  });
  const user = ref({} as User);
  const accessToken = ref("");
  const idToken = ref("");
  const expiresAt = ref(0);
  const issuedAt = ref(0);
  const milliseconds = 1000;

  const isAuthenticated = computed(() => {
    if (
      accessToken.value &&
      idToken.value &&
      Math.round(Date.now() / milliseconds) < expiresAt.value
    ) {
      return true;
    }
    return false;
  });

  function login() {
    const _authState = JSON.parse(localStorage.getItem("authState") || "{}");
    if (
      _authState.accessToken &&
      _authState.idToken &&
      _authState.expiresAt &&
      Math.round(Date.now() / milliseconds) < _authState.expiresAt
    ) {
      user.value = _authState.user;
      accessToken.value = _authState.accessToken;
      idToken.value = _authState.idToken;
      expiresAt.value = _authState.expiresAt;
      issuedAt.value = _authState.issuedAt;
    } else {
      auth0.authorize();
    }
  }

  function logout() {
    localStorage.removeItem("authState");

    window.location.href = `https://${
      import.meta.env.AUTH0_DOMAIN
    }/v2/logout?returnTo=${window.location.origin}&client_id=${
      import.meta.env.AUTH0_CLIENT_ID
    }`;
  }

  function handleAuth0Callback() {
    auth0.parseHash((err, authRes) => {
      if (err) {
        logout();
      }

      user.value = {
        email: authRes?.idTokenPayload.email,
        name: authRes?.idTokenPayload.name,
        nickname: authRes?.idTokenPayload.nickname,
        picture: authRes?.idTokenPayload.picture,
      };
      accessToken.value = authRes?.accessToken!;
      idToken.value = authRes?.idToken!;
      expiresAt.value = authRes?.idTokenPayload.exp;
      issuedAt.value = authRes?.idTokenPayload.iat;

      const _authState = {
        user: user.value,
        accessToken: accessToken.value,
        idToken: idToken.value,
        expiresAt: expiresAt.value,
        issuedAt: issuedAt.value,
      };

      localStorage.setItem("authState", JSON.stringify(_authState));

      // Backwards Compatibility for Old Unify
      ls.set("user", JSON.stringify(authRes?.idTokenPayload));
      ls.set("access_token", authRes?.accessToken);
      ls.set("id_token", authRes?.idToken);
      ls.set("token_data", authRes);
      ls.set("start_at", authRes?.idTokenPayload.iat);
      ls.set("expires_at", authRes?.idTokenPayload.exp);
      ls.set("last_activity_at", Math.round(new Date().valueOf() / 1000));
    });
  }

  return {
    isAuthenticated,
    user,
    accessToken,
    idToken,
    expiresAt,
    issuedAt,
    login,
    logout,
    handleAuth0Callback,
  };
});
